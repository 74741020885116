import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Helmet from "../../components/Helmet";
import DashboardCard from "./components/DashboardCard";
import DashboardOrderRevenueChart from "./components/DashboardOrderRevenueChart";

function Dashboard() {
    const { currentShop } = useContext(ShopsContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const getDashboardData = useCallback(async () => {
        setData(null);
        setError(null);
        try {
            const response = await axios.post("/getDashboardData", { shopId: currentShop.id });
            setData(response.data);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [currentShop]);

    useEffect(() => {
        getDashboardData();
    }, [getDashboardData]);

    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Title preTitle="Overzicht">
                Dashboard
            </Title>
            <div className="row">
                <DashboardCard
                    title="Aantal bestellingen"
                    content={ data && data.paidOrderAmount }
                    error={ error }
                />
                { currentShop.entitlementAuthentication && (
                    <DashboardCard
                        title="Aantal geregistreerde klanten"
                        content={ data && data.registeredUsersAmount }
                        subline={ data && (data.activeUsersAmount30Days + " actief de afgelopen 30 dagen") }
                        error={ error }
                    />
                )}
                <DashboardCard
                    title="Omzet"
                    content={ data && new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(data.totalRevenue) }
                    error={ error }
                    subline="excl. btw"
                />
                <div className="col-md-12">
                    <DashboardOrderRevenueChart/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Dashboard);
